// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RVcb8CVBOM9Q41uPRMZG{border-radius:1000px}.nd6bVgd91L8LRhDXbV1w{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block}.fydHfPFbY3JAHi92k8Uc{display:flex;flex-direction:column;justify-content:center;align-items:center}.WEMLWKuuGNXXOuBENsqh{animation:JJG_IG2XZ0XZWSKDzccd 3s infinite;animation-iteration-count:infinite}@keyframes JJG_IG2XZ0XZWSKDzccd{0%{background:var(--py-neutral-1)}30%{background:var(--py-neutral-1)}50%{background:var(--py-neutral-1)}80%{background:var(--py-neutral-1)}100%{background:var(--py-neutral-1)}}.ZvgS440o5WOBlBnBHcyJ{box-sizing:border-box;position:relative;border-radius:100px;border-width:3px;border-style:solid;border-color:hsla(0,0%,100%,.1) #fff #fff #fff;animation-name:xDOBAA0K5U7A13tFORN5;animation-duration:1200ms;animation-iteration-count:infinite;animation-timing-function:ease-in-out}@keyframes xDOBAA0K5U7A13tFORN5{from{transform:rotate(0deg)}to{transform:rotate(1080deg)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": `RVcb8CVBOM9Q41uPRMZG`,
	"text-truncate": `nd6bVgd91L8LRhDXbV1w`,
	"shaquille": `fydHfPFbY3JAHi92k8Uc`,
	"shimmer": `WEMLWKuuGNXXOuBENsqh`,
	"placeHolderShimmer": `JJG_IG2XZ0XZWSKDzccd`,
	"main": `ZvgS440o5WOBlBnBHcyJ`,
	"spin": `xDOBAA0K5U7A13tFORN5`
};
export default ___CSS_LOADER_EXPORT___;
